import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar as farCalendar,
  faCircle as farCircle,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowRightFromBracket,
  faCalendar,
  faCalendarDays,
  faCamera,
  faChalkboard,
  faChartColumn,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClockRotateLeft,
  faClosedCaptioning,
  faComment,
  faCommentSlash,
  faCompress,
  faCopy,
  faDesktop,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faEllipsisH,
  faEllipsisVertical,
  faEnvelope,
  faEraser,
  faExpand,
  faEye,
  faEyeSlash,
  faFaceFrown,
  faFaceLaugh,
  faFaceSmile,
  faFile,
  faFileCircleCheck,
  faFileDownload,
  faFilePdf,
  faFileText,
  faFileVideo,
  faFont,
  faGear,
  faHand,
  faImage,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMessage,
  faMicrochip,
  faMicrophone,
  faMicrophoneLines,
  faMicrophoneSlash,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPhoneSlash,
  faPlay,
  faPlus,
  faPowerOff,
  faQuestionCircle,
  faRightFromBracket,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faSignOutAlt,
  faSliders,
  faSmile,
  faSmileBeam,
  faSortDown,
  faSquarePollHorizontal,
  faSquarePollVertical,
  faStar,
  faSync,
  faTag,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTrash,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserAlt,
  faUserCheck,
  faUserClock,
  faUserPlus,
  faUsers,
  faUserShield,
  faUserSlash,
  faUserTimes,
  faVideo,
  faVideoSlash,
  faVolumeUp,
  faWifi,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';

library.add(
  faUser,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faDesktop,
  faPhoneSlash,
  faChartColumn,
  faMessage,
  faUsers,
  faTimes,
  faPaperPlane,
  faSearch,
  faUserPlus,
  faEllipsisH,
  faEllipsisVertical,
  faCopy,
  faFileDownload,
  faPen,
  faTrash,
  faPlay,
  faCheck,
  faRightFromBracket,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faVolumeUp,
  faXmark,
  farCalendar,
  faCalendar,
  faRotate,
  faEye,
  faEyeSlash,
  faGear,
  faCheckCircle,
  faTimesCircle,
  faUserAlt,
  faCompress,
  faChevronUp,
  faChevronDown,
  faRotateLeft,
  faRotateRight,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlass,
  faExpand,
  faEraser,
  faImage,
  faPlus,
  faComment,
  faCommentSlash,
  faPaperclip,
  faFile,
  faChalkboard,
  faSmile,
  faFont,
  faCamera,
  faClosedCaptioning,
  faEnvelope,
  faDownload,
  faPowerOff,
  faMicrochip,
  faSquarePollVertical,
  faSquarePollHorizontal,
  faCircleNotch,
  faCircle,
  faSortDown,
  faSmileBeam,
  faFaceSmile,
  faFaceLaugh,
  faFaceFrown,
  faAddressCard,
  faPenToSquare,
  faTag,
  faStar,
  faSliders,
  farCircle,
  faSync,
  faChartPie,
  faLock,
  faUserShield,
  faWifi,
  faUserTimes,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faThumbTack,
  faEllipsisVertical,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faQuestionCircle,
  faMicrophoneLines,
  faUserCheck,
  faUserSlash,
  faUserClock,
  faSignOutAlt,
  faCalendarDays,
  faFileText,
  faFileVideo,
  faFilePdf,
  faClockRotateLeft,
  faLink,
  faHand,
  faTriangleExclamation,
  faArrowRightFromBracket,
  faFileCircleCheck,
);
