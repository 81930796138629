<script setup lang="ts">
import UiIcon from '@/components/v2/UiIcon.vue';
import { useNotifications } from '@/composables';

const { notifications, removeNotification } = useNotifications();
</script>

<template>
  <TransitionGroup
    class="app-notifications"
    tag="ul"
  >
    <li
      v-for="notification in notifications"
      :key="notification.id"
      class="ui-notification"
      :class="[
        `ui-notification--${notification.type}`,
      ]"
    >
      <div class="ui-notification__content">
        <UiIcon
          name="exclamation"
          size="16"
        />

        <p class="ui-notification__summary">
          <template v-if="typeof notification.summary === 'string'">
            {{ notification.summary }}
          </template>

          <component
            :is="notification.summary"
            v-else
          />
        </p>

        <UiIcon
          name="times"
          size="14"
          interactive
          @click="removeNotification(notification.id)"
        />
      </div>
    </li>
  </TransitionGroup>
</template>

<style scoped lang="scss">
.app-notifications {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1000;
  max-width: min(350px, 100vw - 20px);
  width: 100%;
}

.ui-notification {
  position: relative;
  margin-bottom: 10px;
  backdrop-filter: blur(10px);
  background-color: color-mix(in srgb, currentColor, transparent 84%);

  &--error {
    color: $desktop-red;
  }

  &--warning {
    color: $desktop-yellow;
  }

  &--info {
    color: $desktop-main-blue;
  }

  &--success {
    color: $desktop-green;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid currentColor;
    border-radius: 6px;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1 1 auto;

    color: $white;
    font-size: 16px;
    line-height: 1em;
  }
}

.v-enter-from {
  opacity: 0;
  transform: translateY(50%);
}

.v-leave-from {
  max-height: 1000px;
}

.v-leave-to {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.v-enter-active {
  transition:
    transform 0.3s,
    opacity 0.3s;
}

.v-leave-active {
  transition:
    max-height 0.45s cubic-bezier(0, 1, 0, 1),
    opacity 0.3s,
    margin-bottom 0.3s;
}
</style>
