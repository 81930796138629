<script lang="ts" setup>
import type { SvgSize } from '@/utils/common';
import type { PropType } from 'vue';
import { getFormattedSize } from '@/utils/common';
import { computed } from 'vue';

/// // props /////

const props = defineProps({
  path: {
    type: String,
    default: '',
  },
  size: {
    type: [Number, String] as PropType<SvgSize>,
    default: 20,
  },
});

/// // computed from component /////

const svgStyles = computed(() => {
  const formattedSize = getFormattedSize(props.size);

  return {
    'backgroundImage': `url(${props.path})`,
    '--svgImageSize': formattedSize,
  };
});
</script>

<template>
  <span
    class="svg-image"
    :style="svgStyles"
  />
</template>

<style scoped>
.svg-image {
  display: block;
  width: var(--svgImageSize);
  height: var(--svgImageSize);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
