import type { UpdateUserPayload, User } from '@/api/users/types';
import type { MeetingActions } from '@/constants/roles';
import type { MemberRole } from '@index5/face-detector';
import { getPermissions } from '@/api/access';
import { createGuestUser } from '@/api/authorization';
import { Roles } from '@/api/organization/types';
import {
  deleteUser as deleteUserApi,
  getCurrentUser,
  updateUser,
} from '@/api/users';
import { MeetingUserRole } from '@/constants/roles';
import { createLogger } from '@/utils';
import * as Sentry from '@sentry/vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useAuthStore } from './auth';

export const useUserStore = defineStore('user', () => {
  const logger = createLogger('🍍user');

  const authStore = useAuthStore();

  const profile = ref<User>();
  const role = ref<MemberRole>();
  const permissions = reactive<Map<MeetingActions, boolean>>(new Map());

  const userId = computed(() => profile.value?.id);
  const userAvatar = computed(() => profile.value?.avatar_url);
  const isGuest = computed(() => !!profile.value?.is_guest);
  const isOrganizer = computed(
    () => role.value === MeetingUserRole.Organizer,
  );
  const isAdmin = computed(
    () =>
      role.value === MeetingUserRole.Organizer
      || role.value === MeetingUserRole.CoOrganizer,
  );

  const isOrganizationAvailable = computed(() => {
    const { organizationRole, organizationId, isSuperAdmin }
            = profile.value ?? {};

    return (
      isSuperAdmin
      || (organizationRole === Roles.LOCAL_ADMIN && organizationId)
    );
  });

  function can(action: MeetingActions) {
    return permissions.get(action) ?? false;
  }

  async function loadProfile() {
    if (!authStore.accessToken) return;

    const response = await getCurrentUser();

    if (!response.success) {
      logger.error(response.message);
      return authStore.logout();
    }

    profile.value = response.data;
  }

  async function updateProfile(data: Partial<UpdateUserPayload>) {
    if (!profile.value?.id) return;

    const response = await updateUser({
      id: profile.value.id,
      ...data,
    });

    if (!response.success) {
      logger.error(response.message);
      return;
    }

    await authStore.refreshTokens();

    profile.value = { ...profile.value, ...data };
  }

  async function createGuest(nickname: string) {
    const response = await createGuestUser(nickname);

    if (!response.success) {
      logger.error(response.message);
      return;
    }

    const { data } = response;

    authStore.setTokens(data.access_token, data.refresh_token);

    return loadProfile();
  }

  async function deleteUser() {
    if (!userId.value) return;

    const { success } = await deleteUserApi(userId.value);

    if (success) authStore.logout();
  }

  async function handlePermissions(meetingId: string) {
    const response = await getPermissions(meetingId);

    if (!response.success) {
      logger.error(response.message);
      return;
    }

    const { data } = response;
    permissions.clear();

    data.permissions.forEach(({ action, roles }) => {
      permissions.set(
        action,
        roles.some(({ role }) => role === data.me.role),
      );
    });

    role.value = data.me.role;
  }

  watch(
    profile,
    (profile) => {
      if (!profile) {
        return Sentry.setUser(null);
      }

      Sentry.setUser({
        id: profile.id,
        email: profile.email,
        username: profile.nickname,
      });
    },
    { immediate: true },
  );

  return {
    profile,
    userId,
    userAvatar,
    isGuest,
    isOrganizer,
    isAdmin,
    isOrganizationAvailable,
    can,
    loadProfile,
    updateProfile,
    createGuest,
    deleteUser,
    handlePermissions,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
