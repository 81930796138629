import { BREAKPOINTS } from '@/constants';

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';
import { ru } from 'vuetify/locale';
import 'vuetify/styles';

export const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'ru',
    messages: { ru },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: { fa, mdi },
  },
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        dark: true,
        colors: {
          background: '#18191d',
        },
      },
    },
  },
  display: {
    thresholds: BREAKPOINTS,
  },
});
