<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, defineAsyncComponent } from 'vue';

const props = defineProps({
  mode: {
    type: String as PropType<Mode>,
    default: 'primary',
  },
  to: {
    type: String,
    default: null,
  },
  href: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'button',
  },
  icon: {
    type: Boolean,
    default: false,
  },
  iconRight: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  stretch: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String as PropType<Size>,
    default: 'large',
  },
  flat: {
    type: Boolean,
    default: false,
  },
  pending: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
});

const slots = defineSlots<{
  icon?: () => unknown;
  default?: () => unknown;
}>();

const DLink = defineAsyncComponent(() => import('./DLink.vue'));

/// // props /////

type Size = 'small' | 'medium' | 'regular' | 'large' | 'x-large';
type Mode =
  | 'primary'
  | 'secondary'
  | 'ternary'
  | 'success'
  | 'dark'
  | 'danger'
  | 'link'
  | 'white'
  | 'warning'
  | 'gray';

/// // computed from component /////

const type = computed(() => (props.href || props.to ? null : props.type));
const component = computed(() =>
  props.to ? DLink : props.href ? 'a' : 'button',
);

const btnClasses = computed(() => [
  'btn',
  `btn--${props.mode}`,
  `btn--${props.size}`,
  {
    'btn--rounded': props.rounded,
    'btn--icon': props.icon,
    'btn--stretch': props.stretch,
    'btn--flat': props.flat,
    'btn--pending': props.pending,
  },
]);

const iconClasses = computed(() => [
  'btn__icon',
  {
    'btn__icon--right': props.iconRight,
  },
]);
</script>

<template>
  <component
    :is="component"
    :class="btnClasses"
    :to="to"
    :href="href"
    :type="type"
  >
    <span
      v-if="pending"
      :class="iconClasses"
    >
      <fa-icon
        :icon="['fas', 'circle-notch']"
        spin
      />
    </span>

    <template v-else>
      <span
        v-if="slots.icon"
        :class="iconClasses"
      >
        <slot name="icon" />
      </span>

      <span
        v-if="!icon"
        class="btn__label"
      >
        <slot>{{ label }}</slot>
      </span>
    </template>
  </component>
</template>
