import type { Meeting } from '@/api/meetings/types';
import type { CreateWebinarLinkPayload } from '@/interfaces/utils';
import type { RouteLocationNamedRaw } from 'vue-router';
import { USER_AVATAR_PLACEHOLDER } from '@/constants';
import i18n from '@/i18n';
import { useUserStore } from '@/pinia/stores/user';
import router from '@/router';
import dayjs from 'dayjs';

export type SvgSize = number | 'auto-fit';

export function getFormattedSize(size: SvgSize) {
  const isSizeAutoFit = size === 'auto-fit';
  return isSizeAutoFit ? '100%' : `${size}px`;
}

export function onAvatarLoadError(e: Event) {
  (e.target as HTMLImageElement).src = USER_AVATAR_PLACEHOLDER;
}

export function replaceS3Domain(url: string): string {
  return url.replace(
    import.meta.env.VUE_APP_S3_DOMAIN,
    import.meta.env.VUE_APP_SELECTEL_DOMAIN,
  );
}

export function capitalize(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function createWebinarLink(payload: CreateWebinarLinkPayload) {
  const { name, organizer, id, date } = payload;
  const { t, locale } = i18n.global;

  const isPlanned = date.start.toTimeString() !== date.created.toTimeString();
  const format = isPlanned ? 'D MMMM, HH:mm' : 'D MMMM';
  const formattedDate = dayjs(date.start).format(format);
  const weekday = capitalize(dayjs(date.start).format('dddd'));

  return t('webinar-link', {
    name,
    organizer,
    date: `${weekday}, ${formattedDate}`,
    link: `${window.location.origin}/${locale.value}/rooms/${id}`,
  });
}

export function redirect(to: RouteLocationNamedRaw) {
  const locale = i18n.global.locale.value;

  const params = 'params' in to ? to.params ?? {} : {};
  return router.push({ name: to.name, params: { ...params, locale } });
}

function getFileUrl(blob: Blob | string) {
  return blob instanceof Blob
    ? URL.createObjectURL(blob)
    : fetch(blob)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob));
}

export async function downloadFile(
  blob: Blob | string,
  name: string,
  format?: string,
): Promise<void> {
  const url = await getFileUrl(blob);
  const link = document.createElement('a');
  const fileName = format ? `${name}.${format}` : name;

  link.setAttribute('download', fileName);
  link.href = url;

  document.body.appendChild(link);

  link.click();
  link.remove();

  URL.revokeObjectURL(url);
}

export function getStatusColorByAttention(attention: number) {
  if (attention <= 20) return '#FA5555';
  if (attention <= 60) return '#FF9D0A';

  return '#67C23A';
}

export function generateRandomColor(): string {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function requireAsset(path: string): string {
  return new URL(`../assets/${path}`, import.meta.url).href;
}

export function copyLink(
  text: string,
  { onError, onSuccess }: {
    onError?: (err: unknown) => void;
    onSuccess?: () => void;
  } = {},
) {
  return navigator.clipboard.writeText(text).then(onSuccess).catch(onError);
}

export function copyMeetingLink(meeting: Meeting) {
  const userStore = useUserStore();

  return copyLink(
    createWebinarLink({
      id: meeting.id,
      name: meeting.name,
      organizer: userStore.profile?.nickname ?? '',
      date: {
        start: new Date(meeting.start_date_time),
        created: new Date(meeting.createdAt),
      },
    }),
  );
}
