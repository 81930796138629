import type { ApiFailureResponse, ErrorResponse } from './types';
import i18n from '@/i18n';
import { useAuthStore } from '@/pinia/stores/auth';
import * as Sentry from '@sentry/vue';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VUE_APP_API_HOST}api/v1`,
  responseType: 'json',
  withCredentials: true,
  params: {},
});

axiosInstance.interceptors.request.use(async (req) => {
  const authStore = useAuthStore();

  req.params.lang = i18n.global.locale.value;

  if (authStore.accessToken) {
    req.headers.Authorization = `Bearer ${authStore.accessToken}`;
  }

  return req;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const authStore = useAuthStore();
    const status = error?.response?.status || 200;
    const isRefresh = error?.config?.url?.includes(
      'authorization/token-refresh',
    );

    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setFingerprint([
        (error.config.method, error.config.url, String(status)),
      ]);

      Sentry.captureException(error);
    });

    if (status === 401 && !isRefresh) {
      const { success } = await authStore.refreshTokens();

      if (!success) {
        return Promise.reject(error);
      }

      error.config.headers.Authorization = `Bearer ${authStore.accessToken}`;

      return axiosInstance.request(error.config);
    }

    return Promise.reject(error);
  },
);

export const handleApiError = (error: unknown): ApiFailureResponse => {
  let message = '';
  let statusCode = 500;
  let timeLeft = null;

  if (axios.isAxiosError<ErrorResponse>(error)) {
    if (error.response) {
      const { data, status } = error.response;
      statusCode = data.statusCode ?? status;
      timeLeft = data.timeLeft;

      if (Array.isArray(data.message)) {
        message = data.message[0];
      } else {
        message = data.message;
      }
    } else {
      message = error.message;
    }
  } else if (error instanceof Error) {
    message = error.message;
  }

  return { message, statusCode, success: false, timeLeft };
};

export default axiosInstance;
