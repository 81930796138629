import type { Component } from 'vue';
import { createSharedComposable } from '@vueuse/core';
import { ref } from 'vue';

export interface Notification {
  id: string;
  type: 'success' | 'info' | 'warning' | 'error';
  summary: string | Component;
  life?: number;
}

export const useNotifications = createSharedComposable(() => {
  const notifications = ref<Notification[]>([]);

  const addNotification = (
    notification: Omit<Notification, 'id'>,
    customId?: string,
  ) => {
    const id = customId || crypto.randomUUID();
    notifications.value.push({ ...notification, id });

    if (notification.life) {
      setTimeout(() => {
        removeNotification(id);
      }, notification.life);
    }

    return () => removeNotification(id);
  };

  const removeNotification = (id: string) => {
    notifications.value = notifications.value.filter(
      (item) => item.id !== id,
    );
  };

  return {
    notifications,
    addNotification,
    removeNotification,
  };
});
