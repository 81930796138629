<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';

export interface SelectOption {
  title: string;
  value: string;
}

/// // props /////

defineProps({
  label: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: [String, Number],
    default: 'unset',
  },
});
const compInstance = getCurrentInstance();
const compId = compInstance?.uid || Math.random();

/// // computed from component /////

const id = computed(() => {
  return `select-${compId}`;
});
</script>

<template>
  <div
    class="select"
    :style="{ maxWidth }"
  >
    <v-label
      v-if="label"
      class="select__label"
      :for="id"
    >
      {{ label }}
    </v-label>
    <v-select
      v-bind="$attrs"
      :id="id"
      bg-color="#202124"
      menu-icon="fas fa-angle-down"
      :menu-props="{ maxWidth: '100%' }"
    />
  </div>
</template>
