import type { App } from 'vue';
import type { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import pkg from '../../package.json';

export function initSentry(app: App, router: Router) {
  const dsn = import.meta.env.VUE_APP_SENTRY_DSN;
  const enabled = import.meta.env.VUE_APP_SENTRY_ENABLED === 'true';
  const environment = import.meta.env.VUE_APP_SENTRY_ENVIRONMENT;

  Sentry.init({
    app,
    dsn,
    enabled,
    environment,
    release: `${pkg.name}@${pkg.version}`,
    trackComponents: true,
    autoSessionTracking: true,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 6,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}
