import type { Locale } from 'vue-i18n';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { getStorageItem, setStorageItem } from '@/utils';
import createLogger from '@/utils/logger';
import dayjs from 'dayjs';
import { nextTick } from 'vue';
import { createI18n, useI18n as useVueI18n } from 'vue-i18n';
import ru from './locales/ru.json';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

const logger = createLogger('i18n');

type MessageSchema = typeof ru;

export function isSupportedLocale(locale: string) {
  return ['en', 'ru'].includes(locale);
}

export function getUserLocale(): string {
  const navigatorLanguage = navigator.language.split('-')[0];
  const fallbackLocale = isSupportedLocale(navigatorLanguage)
    ? navigatorLanguage
    : 'ru';

  return getStorageItem(LOCAL_STORAGE_KEYS.locale, fallbackLocale);
}

export async function setI18nLanguage(locale: Locale) {
  try {
    await loadLocaleMessages(locale);

    i18n.global.locale.value = locale;
    const html = document.querySelector('html') as HTMLHtmlElement;

    dayjs.locale(locale);
    setStorageItem(LOCAL_STORAGE_KEYS.locale, locale);

    if (html.lang !== locale) {
      html.setAttribute('lang', locale);
    }
  } catch (e) {
    logger.error(e);
  }
}

export async function loadLocaleMessages(locale: Locale) {
  if (i18n.global.availableLocales.includes(locale)) return;

  const messages = await import(`@/i18n/locales/${locale}.json`);
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

const locale = getUserLocale();
const i18n = createI18n<[MessageSchema], string, false>({
  legacy: false,
  locale,
  fallbackLocale: 'en',
  messages: { ru },
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

setI18nLanguage(locale);

export const useI18n = useVueI18n<{ message: MessageSchema }, 'ru'>;

export default i18n;
