<script lang="ts" setup>
import type {
  DatePickerInstance,
  ModelValue,
  TimeModel,
  VueDatePickerProps,
} from '@vuepic/vue-datepicker';
import { useI18n } from '@/i18n';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useDebounceFn } from '@vueuse/core';
import dayjs from 'dayjs';
import { computed, onMounted, ref } from 'vue';
import { VueScrollPicker } from 'vue-scroll-picker';
import 'vue-scroll-picker/lib/style.css';
import '@vuepic/vue-datepicker/dist/main.css';

interface Props {
  timePicker?: boolean;
  name?: string;
  minDate?: Date;
  dateFormat?: string;
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  timePicker: false,
  dateFormat: 'DD.MM.YY',
  minDate: () => new Date(new Date().setHours(0, 0, 0)),
});

const modelValue = defineModel<ModelValue>({ default: new Date() });

const timePickerValue = ref<TimeModel>({ hours: 0, minutes: 0 });

const { t, locale } = useI18n();

const datepickerNode = ref<DatePickerInstance | null>(null);

const ariaLabels = ref({
  nextMonth: t('date-picker.next-month'),
  prevMonth: t('date-picker.next-prev'),
});
const datepickerPosition = ref<VueDatePickerProps['position']>('center');
const dayNames = computed(() => {
  return Array.from({ length: 7 }).map((_, i) =>
    t(`date-picker.day-names.${i + 1}`),
  );
});

const menuClassName = computed(() => {
  let menuClass = 'datepicker__menu';

  if (props.timePicker) {
    menuClass += ' datepicker__menu--time';
  }

  return menuClass;
});

const isTimeModel = (modelValue: ModelValue): modelValue is TimeModel => {
  return (modelValue as TimeModel).hours !== undefined;
};

const updateTimeList = (time: number, type: 'hours' | 'minutes') => {
  const timeList = type === 'hours' ? hoursList : minutesList;
  const currentTime
        = type === 'hours'
          ? +timePickerValue.value.hours
          : +timePickerValue.value.minutes;

  if (currentTime > time) {
    timeList.value.unshift(timeList.value.pop()!);
  } else {
    timeList.value.push(timeList.value.shift()!);
  }

  const timeIndex = timeList.value.findIndex((item) => item.value === time);
  const length = timeList.value.length;

  if (timeIndex >= timeList.value.length - 4) {
    timeList.value.push(...timeList.value.splice(0, length / 2));
  } else if (timeIndex <= 4) {
    timeList.value.unshift(...timeList.value.splice(length / 2));
  }
};

const handleTimeUpdate = (time: number, type: 'hours' | 'minutes') => {
  updateTimeList(time, type);

  if (isTimeModel(modelValue.value)) {
    modelValue.value = timePickerValue.value;
  }
};

const hoursList = ref(
  Array.from({ length: 24 }).map((_, i) => ({
    name: formatTime(i),
    value: i,
  })),
);

const minutesList = ref(
  Array.from({ length: 60 }).map((_, i) => ({
    name: formatTime(i),
    value: i,
  })),
);

function format(date: Date) {
  if (props.timePicker) {
    return dayjs(date).format('HH:mm');
  }

  return dayjs(date).format(props.dateFormat);
}

const setPosition = useDebounceFn(() => {
  if (props.timePicker) return;

  if (window.innerWidth < 550) {
    datepickerPosition.value = 'left';
    return;
  }

  datepickerPosition.value = 'center';
}, 300);

function formatTime(time: number) {
  return `${time < 10 ? `0${time}` : time}`;
}

onMounted(() => {
  if (isTimeModel(modelValue.value)) {
    timePickerValue.value = modelValue.value;
  }

  const { hours, minutes } = timePickerValue.value;

  updateTimeList(+hours, 'hours');
  updateTimeList(+minutes, 'minutes');
});
</script>

<template>
  <VueDatePicker
    ref="datepickerNode"
    v-model="modelValue"
    month-name-format="long"
    week-start="1"
    auto-apply
    dark
    class="datepicker"
    :class="[{ 'datepicker--time': timePicker }]"
    :menu-class-name="menuClassName"
    :locale="locale"
    :min-date="minDate"
    :format="format"
    :aria-labels="ariaLabels"
    :enable-time-picker="timePicker"
    :time-picker="timePicker"
    :day-names="dayNames"
    :clearable="false"
    :position="datepickerPosition"
    @recalculate-position="setPosition"
  >
    <template #month-year="{ month, year, months, handleMonthYearChange }">
      <div class="datepicker__month-year month-year">
        <span class="month-year__title">
          {{ `${months[month].text} ${year}` }}
        </span>

        <div class="month-year__buttons">
          <button
            class="month-year__button"
            type="button"
            :aria-label="ariaLabels.prevMonth"
            @click="handleMonthYearChange(false)"
          >
            <fa-icon
              color="#18191D"
              :icon="['fas', 'angle-left']"
            />
          </button>

          <button
            class="month-year__button"
            type="button"
            :aria-label="ariaLabels.nextMonth"
            @click="handleMonthYearChange(true)"
          >
            <fa-icon
              color="#18191D"
              :icon="['fas', 'angle-right']"
            />
          </button>
        </div>
      </div>
    </template>

    <template #dp-input="{ value }">
      <slot
        name="dp-input"
        :value="value"
      >
        <div class="dp-input">
          <d-input
            type="text"
            readonly
            :name="name"
            :model-value="value"
          />

          <div class="dp-input__icon">
            <fa-icon
              class="w-[16px] h-[16px]"
              color="#616266"
              :icon="['fas', 'angle-down']"
            />
          </div>
        </div>
      </slot>
    </template>

    <template #time-picker-overlay>
      <div class="time-picker">
        <VueScrollPicker
          v-model="timePickerValue.hours"
          :options="hoursList"
          @update:model-value="handleTimeUpdate($event, 'hours')"
        />

        <VueScrollPicker
          v-model="timePickerValue.minutes"
          :options="minutesList"
          @update:model-value="handleTimeUpdate($event, 'minutes')"
        />
      </div>
    </template>
  </VueDatePicker>
</template>

<style scoped lang="scss">
.month-year {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 14px;
    color: #fff;
  }

  &__buttons {
    display: flex;
    gap: 20px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;

    @media screen and (min-width: 400px) {
      width: 32px;
      height: 32px;
    }
  }
}

.dp-input {
  position: relative;

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
  }
}

.time-picker {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
</style>

<style lang="scss">
.dp__menu {
  margin-top: 20px;
}

.vue-scroll-picker {
  height: 140px;

  .vue-scroll-picker-rotator {
    display: flex;
    flex-direction: column;
  }

  .vue-scroll-picker-layer-selection {
    background: #292b30;
  }

  &:nth-child(1) {
    .vue-scroll-picker-rotator {
      align-items: flex-end;
      padding-right: 8px;
    }

    .vue-scroll-picker-layer-selection {
      left: 5px;
      border-radius: 5px 0 0 5px;
    }
  }

  &:nth-child(2) {
    .vue-scroll-picker-rotator {
      align-items: flex-start;
      padding-left: 8px;
    }

    .vue-scroll-picker-layer-selection {
      right: 5px;
      border-radius: 0 5px 5px 0;
    }
  }
}

.vue-scroll-picker-layer-top {
  background: transparent;
  border: none;
}

.vue-scroll-picker-layer-bottom {
  background: transparent;
  border: none;
}

.vue-scroll-picker-item {
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  opacity: 0.1;
  transition: all 0.2s ease;
  padding: 7px 0;
  z-index: 1;

  &:has(+ .vue-scroll-picker-item-selected),
  &-selected + .vue-scroll-picker-item {
    font-size: 18px;
    opacity: 0.2;
  }

  &-selected {
    font-size: 22px;
    opacity: 1;
  }
}

.dp__theme_dark {
  --dp-font-family: inherit;
  --dp-background-color: #202124;
  --dp-text-color: #e4e4e4;
  --dp-primary-color: #1871f8;
  --dp-menu-padding: 10px;
  --dp-cell-padding: 21.5px 13.5px;
  --dp-cell-size: 40px;
  --dp-border-radius: 0;
  --dp-cell-border-radius: 0;
  --dp-row-maring: 0;

  @media screen and (min-width: 400px) {
    --dp-menu-padding: 15px;
    --dp-cell-padding: 24px 13.5px;
    --dp-cell-size: 45px;
  }
}

.dp__calendar_header {
  padding-top: 10px;

  @media screen and (min-width: 400px) {
    padding-top: 20px;
  }
}

.dp__calendar_header_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--dp-cell-size);
  height: 24px;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  padding: 0;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__menu:not(.datepicker__menu--time) {
  @media screen and (max-width: 401px) {
    left: 0 !important;
  }
}

.datepicker {
  min-width: 0;

  &__menu--time {
    --dp-border-radius: 10px;
    --dp-menu-min-width: 185px;
    --dp-menu-border-color: transparent;
    margin-top: 0;
    left: 50% !important;

    overflow: hidden;

    .dp__instance_calendar {
      height: 140px;
    }
  }
}
</style>
