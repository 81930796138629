import type { LOCAL_STORAGE_KEYS } from '@/constants';
import createLogger from './logger';

const logger = createLogger('localStorage');

export type StorageKey = keyof typeof LOCAL_STORAGE_KEYS;

export function getStorageItem<T>(key: StorageKey, defaultValue: T): T {
  try {
    const value = localStorage.getItem(key);

    if (!value) {
      setStorageItem(key, defaultValue);
      return defaultValue;
    }

    return JSON.parse(value);
  } catch (error) {
    logger.error(`Error getting item from local storage: ${error}`);
    return defaultValue;
  }
}

export function setStorageItem<T>(key: StorageKey, value: T): void {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    logger.error(`Error setting item to local storage: ${error}`);
  }
}

export function removeStorageItem(key: StorageKey): void {
  localStorage.removeItem(key);
}
